import React, { useState, useEffect } from "react";
import { axiosInstance } from "../AxiosConfig";
import "../styles/Blogs.scss";
import Logo from "../assets/HomePage/Logos/LOGO-ALBA-HOLDING.png";
import { useParams } from "react-router-dom"; // Para obtener el parámetro de la URL
import { FaShareAlt ,FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Blogpreview from "../components/Blogpreview";
import he from "he"; // Importar he para decodificar HTML

function Banner1() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Blog1">
      {isMobile || isTablet ? (
        <div className="row1">
          <img src={Logo} className="imagen1" alt="Imagen" />
          <div className="IntroduccionBlog">
            <p>
              ¡Definimos <span>nuestro </span>
              <span>propósito</span> al crear <br /> empresas diseñadas para
              alcanzar <span>el éxito!</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="left-column">
          <img src={Logo} className="imagen1" alt="Imagen" />
          <div className="IntroduccionBlog">
            <h1>
              NUESTRO <span>BLOG</span>
            </h1>
            <p>
              Descubre inspiración, ideas y consejos <br />
              para <strong>transformar tu camino al éxito.</strong>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function Banner2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false); // Estado para manejar la animación de la paginación
  const [selectedBlog, setSelectedBlog] = useState(null); // Estado para almacenar el blog seleccionado
  const blogsPerPage = 6;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch blogs from the API
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get("/api/Blog/GetFilteredBlogs", {
          params: {
            tipo: 244, // Filter by type for "Oportunidades Profesionales"
            habilitado: true,
          },
        });
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Función para seleccionar un blog y mostrar su vista previa
  const handleViewMore = (blog) => {
    setSelectedBlog(blog);

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animación suave
    });
  };

  // Función para compartir el blog
  const handleShare = (blog) => {
    if (navigator.share) {
      navigator
        .share({
          title: blog.tipo,
          text: blog.descripcion.replace(/<[^>]+>/g, "").substring(0, 100) + "...",
          url: window.location.href, // URL actual de la página
        })
        .then(() => console.log("Contenido compartido exitosamente"))
        .catch((error) => console.error("Error al compartir:", error));
    } else {
      alert("La funcionalidad de compartir no está soportada en este dispositivo.");
    }
  };

 // Pagination logic
  const totalPages = Math.ceil(blogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage(newPage);
        setIsAnimating(false);
      }, 300); // La duración debe coincidir con la duración de la animación CSS
    }
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };

  const prevPage = () => {
    changePage(currentPage - 1);
  };

  if (selectedBlog) {
    // Renderiza Blogpreview si hay un blog seleccionado
    return (
      <Blogpreview
        blog={selectedBlog}
        onBack={() => setSelectedBlog(null)} // Función para volver al listado de blogs
      />
    );
  }

  return (
    <div className="Blogs2">
      {isMobile || isTablet ? (
        <div className="row1">
          <div className="parrafoAboutus">
            <p>
              OPORTUNIDADES Y <br />
              <span>CRECIMIENTO PROFESIONAL </span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al día con lo último en
              tendencias, inspiración y{" "}
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>
        </div>
      ) : (
        <div className="left-column">
          <div className="parrafoAboutus">
            <p>
              OPORTUNIDADES Y <br />
              <span>CRECIMIENTO PROFESIONAL </span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al <br /> día con lo último en
              tendencias, inspiración y
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>

          {/* Render Blogs */}
          <div className={`blogs-grid ${isAnimating ? "fade-out" : "fade-in"}`}>
            {currentBlogs.map((blog) => (
              <div className="blog-card" key={blog.idBlog}>
                <img
                  src={blog.imagen1}
                  alt={blog.tipo}
                  className="blog-image"
                />
                <p className="Publicacion">Publicado: {new Date(blog.fechaCreacion).toLocaleDateString('es-ES', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                <div className="blog-content">
                  <div className="linea"></div>
                  <h3>{blog.titulo}</h3>
                  <p>
                    {he.decode(blog.descripcion.replace(/<[^>]+>/g, "")).substring(0, 100)}...
                  </p>
                  <div className="content-footer">
                    <button className="blog-button" 
                    onClick={() => handleViewMore(blog)}
                    >
                      LEER MÁS <span>&gt;</span>
                    </button>
                    {/* Ícono de compartir */}
                    <div className="compartir" onClick={() => handleShare(blog)}><FaShareAlt className="share-icon" /></div>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Pagination */}
          <div className="pagination">
            <button onClick={prevPage} className="page-button arrow-button">
              <FaChevronLeft />
            </button>

            <span className="pagination-text">
               <strong>Página {currentPage}</strong> de{" "}
              {totalPages}
            </span>

            <button onClick={nextPage} className="page-button arrow-button">
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}


function Banner3() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false); // Estado para manejar la animación de la paginación
  const [selectedBlog, setSelectedBlog] = useState(null); // Estado para almacenar el blog seleccionado
  const blogsPerPage = 6;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch blogs from the API
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get("/api/Blog/GetFilteredBlogs", {
          params: {
            tipo: 245, // Filter by type for "Oportunidades Profesionales"
            habilitado: true,
          },
        });
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Función para seleccionar un blog y mostrar su vista previa
  const handleViewMore = (blog) => {
    setSelectedBlog(blog);

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animación suave
    });
  };

  // Función para compartir el blog
  const handleShare = (blog) => {
    if (navigator.share) {
      navigator
        .share({
          title: blog.tipo,
          text: blog.descripcion.replace(/<[^>]+>/g, "").substring(0, 100) + "...",
          url: window.location.href, // URL actual de la página
        })
        .then(() => console.log("Contenido compartido exitosamente"))
        .catch((error) => console.error("Error al compartir:", error));
    } else {
      alert("La funcionalidad de compartir no está soportada en este dispositivo.");
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(blogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage(newPage);
        setIsAnimating(false);
      }, 300); // La duración debe coincidir con la duración de la animación CSS
    }
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };

  const prevPage = () => {
    changePage(currentPage - 1);
  };

  if (selectedBlog) {
    // Renderiza Blogpreview si hay un blog seleccionado
    return (
      <Blogpreview
        blog={selectedBlog}
        onBack={() => setSelectedBlog(null)} // Función para volver al listado de blogs
      />
    );
  }


  return (
    <div className="Blogs2">
      {isMobile || isTablet ? (
        <div className="row1">
          <div className="parrafoAboutus">
            <p>
              OPORTUNIDADES Y <br />
              <span>CRECIMIENTO PROFESIONAL </span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al día con lo último en
              tendencias, inspiración y{" "}
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>
        </div>
      ) : (
        <div className="left-column">
          <div className="parrafoAboutus">
            <p>
              INNOVACION Y {""}
               <span>TECNOLOGIA</span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al <br /> día con lo último en
              tendencias, inspiración y
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>

          {/* Render Blogs */}
          <div className={`blogs-grid ${isAnimating ? "fade-out" : "fade-in"}`}>
            {currentBlogs.map((blog) => (
              <div className="blog-card" key={blog.idBlog}>
                <img
                  src={blog.imagen1}
                  alt={blog.tipo}
                  className="blog-image"
                />
                <p className="Publicacion">Publicado: {new Date(blog.fechaCreacion).toLocaleDateString('es-ES', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                <div className="blog-content">
                  <div className="linea"></div>
                  <h3>{blog.titulo}</h3>
                  <p>
                    {he.decode(blog.descripcion.replace(/<[^>]+>/g, "")).substring(0, 100)}...
                  </p>
                  <div className="content-footer">
                    <button className="blog-button"
                    onClick={() => handleViewMore(blog)}>
                      LEER MÁS <span>&gt;</span>
                    </button>
                    {/* Ícono de compartir */}
                    <div className="compartir" onClick={() => handleShare(blog)}><FaShareAlt className="share-icon" /></div>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>

           {/* Pagination */}
           <div className="pagination">
            <button onClick={prevPage} className="page-button arrow-button">
              <FaChevronLeft />
            </button>

            <span className="pagination-text">
               <strong>Página {currentPage}</strong> de{" "}
              {totalPages}
            </span>

            <button onClick={nextPage} className="page-button arrow-button">
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Banner4() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false); // Estado para manejar la animación de la paginación
  const [selectedBlog, setSelectedBlog] = useState(null); // Estado para almacenar el blog seleccionado
  const blogsPerPage = 6;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch blogs from the API
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get("/api/Blog/GetFilteredBlogs", {
          params: {
            tipo: 246, // Filter by type for "Oportunidades Profesionales"
            habilitado: true,
          },
        });
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Función para seleccionar un blog y mostrar su vista previa
  const handleViewMore = (blog) => {
    setSelectedBlog(blog);

    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animación suave
    });
  };

  // Función para compartir el blog
  const handleShare = (blog) => {
    if (navigator.share) {
      navigator
        .share({
          title: blog.tipo,
          text: blog.descripcion.replace(/<[^>]+>/g, "").substring(0, 100) + "...",
          url: window.location.href, // URL actual de la página
        })
        .then(() => console.log("Contenido compartido exitosamente"))
        .catch((error) => console.error("Error al compartir:", error));
    } else {
      alert("La funcionalidad de compartir no está soportada en este dispositivo.");
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(blogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage(newPage);
        setIsAnimating(false);
      }, 300); // La duración debe coincidir con la duración de la animación CSS
    }
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };

  const prevPage = () => {
    changePage(currentPage - 1);
  };

  if (selectedBlog) {
    // Renderiza Blogpreview si hay un blog seleccionado
    return (
      <Blogpreview
        blog={selectedBlog}
        onBack={() => setSelectedBlog(null)} // Función para volver al listado de blogs
      />
    );
  }


  return (
    <div className="Blogs2">
      {isMobile || isTablet ? (
        <div className="row1">
          <div className="parrafoAboutus">
            <p>
              BIENESTAR Y <br />
              <span>CULTURA EMPRESARIAL </span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al día con lo último en
              tendencias, inspiración y{" "}
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>
        </div>
      ) : (
        <div className="left-column">
          <div className="parrafoAboutus">
            <p>
              OPORTUNIDADES Y <br />
              <span>CRECIMIENTO PROFESIONAL </span>
            </p>
            <p className="texto">
              Entérate de las novedades, noticias y consejos que hemos preparado
              para ti. Explora nuestro blog y mantente al <br /> día con lo último en
              tendencias, inspiración y
              <strong>
                todo lo que necesitas saber para alcanzar tus metas
              </strong>
              .
            </p>
          </div>

          {/* Render Blogs */}
          <div className={`blogs-grid ${isAnimating ? "fade-out" : "fade-in"}`}>
            {currentBlogs.map((blog) => (
              <div className="blog-card" key={blog.idBlog}>
                <img
                  src={blog.imagen1}
                  alt={blog.tipo}
                  className="blog-image"
                />
                <p className="Publicacion">Publicado: {new Date(blog.fechaCreacion).toLocaleDateString('es-ES', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                <div className="blog-content">
                  <div className="linea"></div>
                  <h3>{blog.titulo}</h3>
                  <p>
                    {he.decode(blog.descripcion.replace(/<[^>]+>/g, "")).substring(0, 100)}...
                  </p>
                  <div className="content-footer">
                    <button className="blog-button"
                    onClick={() => handleViewMore(blog)}>
                      LEER MÁS <span>&gt;</span>
                    </button>
                    {/* Ícono de compartir */}
                    <div className="compartir" onClick={() => handleShare(blog)}><FaShareAlt className="share-icon" /></div>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>

           {/* Pagination */}
           <div className="pagination">
            <button onClick={prevPage} className="page-button arrow-button">
              <FaChevronLeft />
            </button>

            <span className="pagination-text">
               <strong>Página {currentPage}</strong> de{" "}
              {totalPages}
            </span>

            <button onClick={nextPage} className="page-button arrow-button">
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Blogs() {
  const { category } = useParams(); // Obtener la categoría desde la URL

  return (
    <>
      {/* Banner1 siempre se renderiza */}
      <Banner1 />

      {/* Renderiza Banner2 solo si la categoría es 'oportunidades' */}
      {category === "oportunidades" && <Banner2 />}

      {/* Aquí puedes agregar más banners condicionales según otras categorías */}
      {category === "innovacion" && <Banner3 />}
      {category === "bienestar" && <Banner4 />}
    </>
  );
}

export default Blogs;
